import React from "react"
import tw, { styled, css } from "twin.macro"
import Input from "./form-input"
import Select from "./form-select"
import Button from "./button"

const Form = styled.form(() => [
  tw`relative z-50 flex flex-col items-center justify-between h-full px-20 py-12 mx-auto mb-0 font-bold bg-white border-marrone-tan font-mont`,
  css`
    width: 700px;
    height: 105%;
    transform: translateY(-2.5%);
    border-width: 16px;

    @media (max-width: 1199px) {
      margin-bottom: 2rem;
    }
    @media (max-width: 899px) {
      margin-bottom: 4rem;
    }
    @media (max-width: 720px) {
      width: initial;
    }

    @media (max-width: 640px) {
      margin-bottom: 6rem;

      ${tw`px-4`}
    }
  `,
])

const Title = tw.h2`font-mont text-2xl mb-20 font-bold text-center`
const Response = tw.p`font-bold transition-all duration-500 ease-in-out font-mont text-center w-full overflow-hidden max-h-0`

class GiveawayForm extends React.Component {
  constructor(props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.formRef = React.createRef()
    this.responseRef = React.createRef()
  }

  validate() {
    const form = this.formRef.current
    const inputs = form.querySelectorAll("input,textarea,select")
    let errors = 0
    inputs.forEach(input => {
      const error = input.parentElement.querySelector(".error")
      if (!input.validity.valid) {
        if (error) error.classList.add("active")
        input.setAttribute("aria-invalid", true)
        errors++
      } else {
        if (error) error.classList.remove("active")
        input.removeAttribute("aria-invalid")
      }
    })
    return errors === 0
  }

  handleSubmit(event) {
    event.preventDefault()
    const response = this.responseRef.current
    if (this.validate()) {
      const form = event.target.closest("form")
      fetch(`${form.action}.json`, {
        method: "POST",
        body: new FormData(form),
        headers: new Headers({
          Accept: "application/json",
        }),
      })
        .then(res => res.json())
        .then(data => {
          response.innerText = "Thank you for your submission!"
          response.style.maxHeight = `calc(${response.scrollHeight}px + 2rem)`
          response.style.paddingTop = `2rem`
          response.style.color = null
          const event = new Event("nextlevel_email_sent")
          document.dispatchEvent(event)
          if (process.env.NODE_ENV === "development")
            console.log("Basin response:", data)
        })
        .catch(err => {
          response.innerText =
            "There was an error submitting your form. Please try again."
          response.style.maxHeight = `calc(${response.scrollHeight}px + 2rem)`
          response.style.paddingTop = `2rem`
          response.style.color = "#F56565"
          console.error(err)
        })
    } else {
      response.style.maxHeight = null
      response.style.paddingTop = null
    }
  }

  render() {
    const { endpoint, title, spanish } = this.props
    return (
      <Form ref={this.formRef} action={endpoint} method="post">
        <Title>{title}</Title>
        <Input
          id="footer-first"
          type="text"
          label="First Name"
          name="First Name"
          errorMsg="Please provide your first name."
          required
        />
        <Input
          id="footer-last"
          type="text"
          label="Last Name"
          name="Last Name"
          errorMsg="Please provide your last name."
          required
        />
        <Input
          id="footer-email"
          type="email"
          label="Email"
          name="Email"
          errorMsg={"Please provide your email."}
          required
        />
        <Button
          className="px-20 py-6 mt-12 text-xs leading-none whitespace-no-wrap solid-button giveaway"
          onClick={this.handleSubmit}
        >
          ENTER ME NOW!
        </Button>
        <Response ref={this.responseRef}>
          Thank you for your submission! Someone from our office will be in
          touch with you shortly.
        </Response>
      </Form>
    )
  }
}

export default GiveawayForm
