import React from "react"
import tw, { styled, css } from "twin.macro"

const Input = styled.input(() => [
  tw`flex w-full py-2 mt-2 mb-6 text-sm font-normal font-bold transition-all duration-500 ease-in-out border-b-2 font-mont`,
  css`
    border-color: #dcdddd;

    &:focus {
      outline: none;
    }

    &[aria-invalid="true"] {
      ${tw`border-red-500`}
    }
  `,
])

const Wrapper = tw.div`w-full`

const TextArea = styled.textarea(() => [
  tw`flex w-full p-2 mt-2 mb-6 text-sm font-normal font-bold transition-all duration-500 ease-in-out border-2 font-mont`,
  css`
    height: 200px;
    border-color: #dcdddd;

    &:focus {
      outline: none;
    }

    &[aria-invalid="true"] {
      ${tw`border-red-500`}
    }
  `,
])

const Error = styled.p(() => [
  tw`flex w-full -mt-4 font-normal text-red-500 transition-all duration-500 ease-in-out opacity-0 pointer-events-none font-mont`,
  css`
    transform: translateY(100%);
    &.active {
      ${tw`opacity-100 pointer-events-auto`}
      transform: none;
    }
  `,
])

const Label = tw.label`text-base font-mont font-bold w-full`

const FormInput = ({
  id,
  name,
  type,
  textarea,
  label,
  required,
  errorMsg,
  ...rest
}) => {
  if (!type) type = "text"
  if (!errorMsg) errorMsg = "There is an error with this field."
  if (required) {
    return (
      <Wrapper className="form-row">
        <Label htmlFor={id}>{label}*</Label>
        {textarea ? (
          <TextArea required id={id} name={name} />
        ) : (
          <Input required type={type} id={id} name={name} {...rest} />
        )}
        <Error className="error">{errorMsg}</Error>
      </Wrapper>
    )
  } else {
    return (
      <Wrapper className="form-row">
        <Label htmlFor={id}>{label}</Label>
        {textarea ? (
          <TextArea required id={id} name={name} />
        ) : (
          <Input required type={type} id={id} name={name} {...rest} />
        )}
        <Error className="error">{errorMsg}</Error>
      </Wrapper>
    )
  }
}

export default FormInput
