import React from "react"
import scrollTo from "gatsby-plugin-smoothscroll"
import { internalLink, relativeLink, URIFragment } from "../../../util/regex"
import { Link } from "gatsby"
import tw from "twin.macro"
import "../../styles/buttons.css"

const Button = props => {
  let { children, to, target, className, onClick, highContrast } = props
  if (!className) className = "button"
  let fontColor
  highContrast ? (fontColor = [tw`text-black`]) : (fontColor = [tw`text-white`])
  if (URIFragment.test(to))
    return (
      <button className={className} onClick={() => scrollTo(to)}>
        {children}
      </button>
    )
  else if (
    to &&
    !target &&
    (new RegExp(internalLink).test(to) || new RegExp(relativeLink).test(to))
  )
    return (
      <Link onClick={onClick} className={className} css={fontColor} to={to}>
        {children}
      </Link>
    )
  else if (to) {
    if (target)
      return (
        <a
          onClick={onClick}
          css={fontColor}
          className={className}
          href={to}
          target={target}
          rel="noreferrer"
        >
          {children}
        </a>
      )
    else
      return (
        <a onClick={onClick} css={fontColor} href={to} className={className}>
          {children}
        </a>
      )
  } else
    return (
      <button onClick={onClick} className={className}>
        {children}
      </button>
    )
}

export default Button
