import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layouts/giveaway"
import SEO from "../components/seo"
import Heading from "../components/modules/heading"
import Outline from "../components/modules/outline"
import tw, { styled, css } from "twin.macro"

import GiveawayForm from "../components/modules/giveaway-form"
import Img from "gatsby-image"

const Disclaimer = styled.p`
  ${tw`w-full px-6 mx-auto mt-4 text-xs font-normal text-center text-white font-mont`}
  max-width: 800px;
`

const Title = tw.div`relative flex pt-32 z-20 pb-24 flex-col items-center justify-center w-full`

const BgTextStyles = [
  tw`absolute z-10 max-w-full overflow-hidden whitespace-nowrap`,
  css`
    left: -1.5rem;
    top: 17rem;
  `,
]

export default ({ data }) => {
  const page = data.wpPage
  const robots = {
    name: `robots`,
    content: `${page.seo.metaRobotsNoindex}, ${page.seo.metaRobotsNofollow}`,
  }
  const Link = styled.a`
    ${tw`font-bold transition-all duration-300 ease-in-out cursor-pointer`}

    &:hover {
      ${tw`text-marrone-bright`}
    }
  `
  const Wrapper = tw.p`absolute font-bold text-white text-center bottom-0 mb-6`

  return (
    <Layout>
      <SEO
        title={page.seo.title}
        description={page.seo.metaDesc}
        meta={[robots]}
        schema={page.seo.schema?.raw}
      />
      <Outline css={BgTextStyles} size="15rem" color="#f7f3e6">
        MARRONE
      </Outline>
      <Title>
        {/* <h1 className="px-4 mt-0 mb-4 text-6xl font-bold text-center font-mont title">
          Win Tickets to See the <br />
          <span>Philadelphia Eagles</span>
          <br /> Play at Lincoln Financial Field!
        </h1> */}
        <Heading centered className="px-4 title">
          {`Win Tickets to See the <br/> <span class="eagles-green">Philadelphia Eagles</span> <br/> Play at Lincoln Financial Field!`}
        </Heading>
      </Title>
      <div className="relative left-0 right-0 z-50 flex flex-col items-center mx-auto mb-16 -mt-16 text-center font-mont w-fulll">
        <div className="relative flex mb-20 bg-marrone-primary spacer"></div>
        <p className="relative px-4 text-3xl font-bold m-width font-mont">
          Do you want to win tickets to see the <br />{" "}
          <span className="eagles-green">Philadelphia Eagles</span> <br /> play
          at Lincoln Financial Field?
        </p>
        <p className="px-4 mt-20 mb-10 text-base m-width font-mont">
          The Marrone Law Firm, LLC is excited to give away a pair of tickets to
          the following games:
        </p>
        <p className="px-4 mb-1 font-bold text-marrone-bright">
          11/07/21: <span className="eagles-green">Eagles</span> vs. Chargers
        </p>
        <p className="px-4 font-bold text-marrone-bright">
          11/21/21: <span className="eagles-green">Eagles</span> vs. Saints
        </p>
        <p className="my-10 font-bold font-mont">Rules to Enter:</p>
        <ol className="list-decimal list-inside">
          <li className="mb-1">
            Like and follow the{" "}
            <Link
              rel="noreferrer"
              href="https://www.facebook.com/marronelawfirm/"
              target="_blank"
              className="mx-0"
            >
              Marrone Law Firm Facebook Page
            </Link>
          </li>
          <li>Enter your email in the field below</li>
        </ol>
        <p tw="mt-10 px-4">
          For both drawings, the winner will be announced two weeks prior to the
          game.
        </p>
        <style jsx>{`
          .spacer {
            height: 13px;
            width: 66px;
          }
          .m-width {
            max-width: 699px;
          }
          p {
            color: #3f3f3f;
          }
          .title {
            max-width: 1100px;
          }
          .eagles-green {
            color: #004c54;
            font-weight: 900;
          }
        `}</style>
      </div>

      <GiveawayForm
        title="Enter Your Email Address for a Chance to Win!"
        endpoint="https://usebasin.com/f/5d2944800670"
      />
      {/* <Eagle src={eagle_footer.sourceUrl} /> */}
      <Img
        className="relative flex object-fill w-full mx-auto mb-0 eagles-footer -mt-28"
        fluid={data.file.childImageSharp.fluid}
        tw="z-behind"
        alt="Eagles Logo"
      />
      <footer className="z-30 footer">
        <div className="footer__copy">
          <Wrapper>
            &copy; {new Date().getFullYear()} Marrone Law Firm LLC. All Rights
            Reserved.
            <Link className="mx-2" to="/sitemap/">
              Sitemap
            </Link>
            |
            <Link className="mx-2" to="/privacy-policy/">
              Privacy Policy
            </Link>
            |
            <Link className="mx-2" to="/disclaimer/">
              Disclaimer
            </Link>
            <Disclaimer>
              *The Marrone Law Firm and this promotion are not affiliated with
              the Philadelphia Eagles Organization. Tickets are
              non-transferrable. Must be at least 21 years of age to enter.
              Winner will be notified by email two weeks prior to games and will
              have 24 hours to respond. If the winner does not claim their
              tickets within the 24 hour period, a new winner will be selected.
            </Disclaimer>
          </Wrapper>
        </div>
      </footer>
    </Layout>
  )
}

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      seo {
        ...SEO
      }
    }

    file(relativePath: { eq: "eagles-footer-image.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`
