import React from "react"
import tw, { styled, css } from "twin.macro"
import { cssSize, hexColor } from "../../../util/regex"

const H2 = styled.h2(({ color, size, centered }) => [
  tw`text-6xl font-mont font-bold mb-4 mt-0`,
  color &&
    css`
      color: ${color};
    `,
  new RegExp(cssSize).test(size) &&
    css`
      font-size: ${size};
    `,
  new RegExp(hexColor).test(color) &&
    css`
      -webkit-text-stroke-color: ${color};
    `,
  centered && tw`text-center`,
  css`
    @media (max-width: 1280px) {
      ${tw`text-5xl`}
    }
    @media (max-width: 640px) {
      ${tw`text-4xl`}
    }
  `,
])

const H1 = styled.h1(({ color, size, centered }) => [
  tw`text-6xl font-mont font-bold mb-4 mt-0`,
  color &&
    css`
      color: ${color};
    `,
  new RegExp(cssSize).test(size) &&
    css`
      font-size: ${size};
    `,
  new RegExp(hexColor).test(color) &&
    css`
      -webkit-text-stroke-color: ${color};
    `,
  centered && tw`text-center`,
  css`
    @media (max-width: 1280px) {
      ${tw`text-5xl`}
    }
    @media (max-width: 640px) {
      ${tw`text-4xl`}
    }
  `,
])

const Heading = ({ children, color, size, centered, className, h1 }) =>
  h1 ? (
    <H1
      className={className ? className : "heading"}
      color={color}
      size={size}
      dangerouslySetInnerHTML={{ __html: children }}
      centered={centered}
    ></H1>
  ) : (
    <H2
      className={className ? className : "heading"}
      color={color}
      size={size}
      dangerouslySetInnerHTML={{ __html: children }}
      centered={centered}
    ></H2>
  )

export default Heading
