import React from "react"
import "../../styles/footer.css"
import { graphql, StaticQuery } from "gatsby"
import AddressList from "../modules/address-list"
import Form from "../modules/footer-form"
import Copyright from "../modules/footer-copyright"
import SocialMedia from "../modules/social-media"

const Footer = () => (
  <StaticQuery
    query={graphql`
      query {
        wp {
          contactInfo {
            contactInfo {
              firmAddresses {
                addressL1
                addressL2
                addressMapsUrl
                faxNumber
                phoneNumber
                title
              }
            }
          }
          socialMedia {
            socialMedia {
              account {
                url
                platform
              }
            }
          }
        }
      }
    `}
    render={data => {
      const fields = data.wp.contactInfo.contactInfo
      const socials = data.wp.socialMedia.socialMedia.account
      return (
        <footer className="footer">
          <div className="footer__content">
            <div className="footer__content--addresses">
              <AddressList items={fields.firmAddresses} />
              <SocialMedia socials={socials} />
            </div>
            <div className="footer__content--form" id="contact">
              <Form
                title="Schedule a Free Consultation"
                endpoint="https://usebasin.com/f/28f28c984d2c"
              />
            </div>
          </div>
          <div className="footer__copy">
            <Copyright />
          </div>
        </footer>
      )
    }}
  />
)

export default Footer
