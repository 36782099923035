import React from "react"
import tw, { styled, css } from "twin.macro"

const Wrapper = tw.div`flex flex-col w-full items-center justify-center`

const Select = styled.select(() => [
  tw`w-full mb-6 text-sm flex cursor-pointer border-b-2 font-normal py-3 font-mont font-bold`,
  css`
    border-color: #dcdddd;

    &:focus {
      outline: none;
    }
  `,
])

const Label = tw.label`text-base mt-2 font-mont font-bold w-full`

const Option = tw.option`font-bold`

const FormSelect = ({ id, name, options, label, required, placeholder }) => {
  const listOptions = options.map(({ title, value }, index) => (
    <Option key={index} value={value}>
      {title}
    </Option>
  ))
  if (required)
    return (
      <Wrapper>
        <Label htmlFor={id}>{label}*</Label>
        <Select required defaultValue={placeholder} id={id} name={name}>
          {listOptions}
        </Select>
      </Wrapper>
    )
  else
    return (
      <Wrapper>
        <Label htmlFor={id}>{label}</Label>
        <Select id={id} name={name} defaultValue={placeholder}>
          {listOptions}
        </Select>
      </Wrapper>
    )
}

export default FormSelect
