import React from "react"
import tw, { styled, css } from "twin.macro"
import Input from "./form-input"
import Select from "./form-select"
import Button from "./button"

const Form = styled.form(() => [
  tw`flex flex-col items-center justify-between h-full px-8 py-12 font-bold bg-white border-8 border-marrone-tan font-mont`,
  css`
    width: 700px;
    height: 105%;
    transform: translateY(-2.5%);

    @media (max-width: 720px) {
      width: initial;
    }

    @media (max-width: 640px) {
      ${tw`px-4`}
    }
  `,
])

const Title = tw.h2`font-mont text-2xl mb-12 font-bold text-center`
const Response = tw.p`font-bold transition-all duration-500 ease-in-out font-mont text-center w-full overflow-hidden max-h-0`

class FooterForm extends React.Component {
  constructor(props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.formRef = React.createRef()
    this.responseRef = React.createRef()
  }

  validate() {
    const form = this.formRef.current
    const inputs = form.querySelectorAll("input,textarea,select")
    let errors = 0
    inputs.forEach(input => {
      const error = input.parentElement.querySelector(".error")
      if (!input.validity.valid) {
        if (error) error.classList.add("active")
        input.setAttribute("aria-invalid", true)
        errors++
      } else {
        if (error) error.classList.remove("active")
        input.removeAttribute("aria-invalid")
      }
    })
    return errors === 0
  }

  handleSubmit(event) {
    event.preventDefault()
    const response = this.responseRef.current
    if (this.validate()) {
      const form = event.target.closest("form")
      fetch(`${form.action}.json`, {
        method: "POST",
        body: new FormData(form),
        headers: new Headers({
          Accept: "application/json",
        }),
      })
        .then(res => res.json())
        .then(data => {
          response.innerText =
            "Thank you for your submission! Someone from our office will be in touch with you shortly."
          response.style.maxHeight = `calc(${response.scrollHeight}px + 2rem)`
          response.style.paddingTop = `2rem`
          response.style.color = null
          const event = new Event("nextlevel_email_sent")
          document.dispatchEvent(event)
          if (process.env.NODE_ENV === "development")
            console.log("Basin response:", data)
        })
        .catch(err => {
          response.innerText =
            "There was an error submitting your form. Please try again."
          response.style.maxHeight = `calc(${response.scrollHeight}px + 2rem)`
          response.style.paddingTop = `2rem`
          response.style.color = "#F56565"
          console.error(err)
        })
    } else {
      response.style.maxHeight = null
      response.style.paddingTop = null
    }
  }

  render() {
    const { endpoint, title, spanish } = this.props
    return (
      <Form ref={this.formRef} action={endpoint} method="post">
        <Title>{spanish ? "Programe Su Consulta Gratuita" : title}</Title>
        <Input
          id="footer-name"
          name="Full Name"
          label={spanish ? "Nombre" : "Full Name"}
          errorMsg={
            spanish
              ? "El campo es obligatorio."
              : "Please provide us with your name."
          }
          required
        />
        <Input
          id="footer-email"
          type="email"
          label={spanish ? "Correo Electrónico" : "Email"}
          name="Email"
          errorMsg={
            spanish ? "El campo es obligatorio." : "Please provide your email."
          }
          required
        />
        <Input
          id="footer-phone"
          type="phone"
          label={spanish ? "Teléfono" : "Phone"}
          name="Phone"
          errorMsg={
            spanish
              ? "El campo es obligatorio."
              : "Please enter a valid phone number."
          }
          required
        />
        <Select
          id="footer-status"
          label={
            spanish
              ? "¿Es usted un cliente nuevo o un cliente actual?"
              : "Are you a New Client or Current Client?"
          }
          placeholder="Select an Option"
          name="Client Status"
          options={[
            { value: "New", title: "New Client" },
            { value: "Existing", title: "Existing Client" },
          ]}
        />
        <Input
          id="footer-msg"
          label={
            spanish
              ? "Breve Descripción De Su Caso"
              : "Are you a New Client or Current Client?"
          }
          textarea
          name="Message"
          required
          errorMsg={
            spanish
              ? "El campo es obligatorio."
              : "Please tell us a little more."
          }
        />
        <Button className="solid-button" onClick={this.handleSubmit}>
          {spanish ? "PROGRAMAR CONSULTA" : "SCHEDULE A FREE CONSULTATION"}
        </Button>
        <Response ref={this.responseRef}>
          Thank you for your submission! Someone from our office will be in
          touch with you shortly.
        </Response>
      </Form>
    )
  }
}

export default FooterForm
